import * as React from "react"
import { graphql } from 'gatsby'

import Header from "../components/header"
import AnimationWrapper from "../components/animationWrapper"
import ContactSection from "../components/contactSection"
import ContactMap from "../components/contactMap"
import Seo from "../components/seo"
import Layout from "../components/layout"

import "./kontakt.css"

const SerwisPage = ({data, location}) => {

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <AnimationWrapper>
        <Header pageName="Serwis" data={data.header} />
      </AnimationWrapper>

      <section id="contact-data" className="page-section full-width">
        <ContactSection data={data.page.frontmatter.contact_section[0]} />
      </section>

      <ContactMap mapUrl={data.page.frontmatter.contact_map_url} />

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "serwis" }, type: { eq: "page" } }) {
      frontmatter{
        title
        contact_section{
          title
          description
          type
          fields
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
        contact_map_url
      }
    }
    header: file(relativePath: {eq: "page-headers/serwis.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            quality: 95
            transformOptions: {fit: COVER}
            webpOptions: {quality: 95}
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
  }
`

export default SerwisPage
